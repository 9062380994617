import React from "react"
import "./TeamReviewsSlider.scss"
import { isEmpty } from "lodash"
import ReactSlider from "react-slick"
import clsx from "clsx"
import Star from "@components/icons/Star"

const TeamReviewsSlider = ({ reviews }) => {
  if (isEmpty(reviews)) return null

  const options = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
  }

  const calculateStars = (rating) => {
    const stars = []
    switch (rating) {
      case "one":
        stars.push(<Star key="star-one" />)
        break
      case "two":
        Array.from({ length: 2 }, (_, i) =>
          stars.push(<Star key={`star-${i}`} />)
        )
        break
      case "three":
        Array.from({ length: 3 }, (_, i) =>
          stars.push(<Star key={`star-${i}`} />)
        )
        break
      case "four":
        Array.from({ length: 4 }, (_, i) =>
          stars.push(<Star key={`star-${i}`} />)
        )
        break
      case "five":
        Array.from({ length: 5 }, (_, i) =>
          stars.push(<Star key={`star-${i}`} />)
        )
        break
      default:
        break
    }
    return stars
  }

  return (
    <div className="reviews-section-wrap">
      <p className="heading">customer reviews</p>
      <div className="reviews-section">
        <ReactSlider
          className={clsx("custom-slider")}
          {...options}
          id="custom-slider"
        >
          {reviews.map((review) => {
            return (
              <div className="review" key={review.id}>
                <div className="stars-wrap">
                  {calculateStars(review.rating)}
                </div>
                <p className="review-text">{review.review}</p>
                <p className="reviewer-name">{review.name}</p>
              </div>
            )
          })}
        </ReactSlider>
      </div>
    </div>
  )
}

export default TeamReviewsSlider
