import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const Star = ({ className, color }) => {
  const stroke = getIconColor(color, "white")
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx("star-icon", className)}
    >
      <path
        d="M15.7691 4.85712C15.8545 4.65179 16.1454 4.65179 16.2308 4.85712L19.0654 11.6724C19.2454 12.1052 19.6525 12.4009 20.1197 12.4384L27.4774 13.0282C27.699 13.046 27.7889 13.3226 27.62 13.4673L22.0143 18.2692C21.6583 18.5742 21.5028 19.0526 21.6116 19.5086L23.3242 26.6884C23.3758 26.9047 23.1405 27.0757 22.9507 26.9598L16.6515 23.1122C16.2515 22.8679 15.7484 22.8679 15.3484 23.1122L9.04922 26.9598C8.85945 27.0757 8.62413 26.9047 8.67573 26.6884L10.3884 19.5086C10.4971 19.0526 10.3417 18.5742 9.98569 18.2692L4.37993 13.4673C4.21104 13.3226 4.30092 13.046 4.52259 13.0282L11.8802 12.4384C12.3475 12.4009 12.7545 12.1052 12.9345 11.6724L15.7691 4.85712Z"
        fill={stroke}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Star
